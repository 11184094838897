<template>
  <div>
    <div>Input Value: {{ text || "Nothing" }}</div>
    <!--    <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>-->
    <v-zxing @decode="onDecode" @loaded="onLoaded" />
  </div>
</template>

<script>
// import {StreamBarcodeReader} from "vue-barcode-reader";

export default {
  name: "CodeScanner",
  components: {
    // StreamBarcodeReader
  },
  props: {
    name: {
      type: String,
      default: () => null,
    },
    autoClose: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    text: "",
    id: null,
  }),
  methods: {
    onDecode(text) {
      console.log("text", text);
      this.text = text;
      this.$emit("onScanned", {
        name: this.name,
        value: this.text,
      });

      if (this.autoClose) {
        this.close();
        return false;
      }

      /*if (this.id) clearTimeout(this.id);
      this.id = setTimeout(() => {
        if (this.text === a) {
          this.text = "";
        }
      }, 5000);*/
    },
    onLoaded() {
      console.log("load");
    },
  },
};
</script>

<style scoped>
.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
</style>
